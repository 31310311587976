<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path :d="topPath" :fill="upColor" :fill-opacity="upOpacity" />
    <path :d="bottomPath" :fill="downColor" :fill-opacity="downOpacity" />
  </svg>
</template>

<script setup lang="ts">
const props = defineProps({
  dir: {
    type: String as PropType<'asc' | 'desc' | 'none'>,
    default: 'none',
  },
})

const upColor = computed((): string => {
  return props.dir === 'asc'
    ? 'var(--themes-success-solid-light)'
    : 'var(--passive-states-disabled-fg)'
})
const downColor = computed((): string => {
  return props.dir === 'desc'
    ? 'var(--themes-success-solid-light)'
    : 'var(--passive-states-disabled-fg)'
})

const upOpacity = computed((): number => {
  return props.dir === 'asc' ? 1 : 0.3
})

const downOpacity = computed((): number => {
  return props.dir === 'desc' ? 1 : 0.3
})

const topPath = `M11.4809 9.05188C11.5469 8.97495 11.5515 8.87716 11.4931 8.79689C11.4347 8.71662 11.3225 
                8.66669 11.2005 8.66669H5.20052C5.07859 8.66669 4.96638 8.71662 4.90795 8.79689C4.84952 
                8.87716 4.85419 8.97495 4.92013 9.05188L7.92013 12.5519C7.98146 12.6234 8.0871 12.6667 
                8.20052 12.6667C8.31394 12.6667 8.41958 12.6234 8.48091 12.5519L11.4809 9.05188Z`
const bottomPath = `M4.92013 6.94812C4.85419 7.02505 4.84952 7.12284 4.90795 7.20311C4.96638 7.28338 
                5.07859 7.33331 5.20052 7.33331H11.2005C11.3225 7.33331 11.4347 7.28338 11.4931 7.20311C11.5515 
                7.12284 11.5469 7.02505 11.4809 6.94812L8.48091 3.44812C8.41958 3.37657 8.31394 3.33331 8.20052 
                3.33331C8.0871 3.33331 7.98146 3.37657 7.92013 3.44812L4.92013 6.94812Z`
</script>
